import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import List from "../../components/custom-widgets/list";

import MobileAppDownloadBtn from "./mobile-app-download-btn";

// TODO: send heading and text in props
const MobileOnlineBanking = ({ sectionClass, content }) => {
  const imgData = useStaticQuery(graphql`
    {
      wafdMobileApp: file(relativePath: { eq: "wafd-bank-mobile-dashboard.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <section id="mobile-online-banking-section" className={sectionClass}>
      <div className="container">
        <div className="row">
          <div className={content.imgColClass}>
            <div className={content.hasTwoImages ? "row" : ""}>
              <div className={`mb-3 mb-lg-0 ${content.hasTwoImages ? "col-sm-12 col-lg-6" : ""}`}>
                <GatsbyImage
                  image={
                    content.primaryImgData.imgVariable
                      ? content.primaryImgData.imgVariable
                      : imgData.wafdMobileApp.childImageSharp.gatsbyImageData
                  }
                  className="w-sm-50 w-md-100 mx-auto"
                  alt={content.primaryImgData.altText}
                />
              </div>
              {content.hasTwoImages ? (
                <div className="d-none d-lg-block col-lg-6">
                  <GatsbyImage image={content.secondaryImgData.imgVariable} alt={content.secondaryImgData.altText} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={content.listColClass}>
            <h2 className={`${content.headingClass}`}>{content.heading}</h2>
            <h3>{content.subHeading}</h3>
            <p>{content.text}</p>
            <List {...content.listData} />

            <div className="row">
              <div className="col-auto mb-3 mb-lg-0">
                <MobileAppDownloadBtn
                  idName="apple-store"
                  storeBtn="apple"
                  url={content.appleStoreUrl}
                  specialBtn={content.heading}
                />
              </div>
              <div className="col-auto">
                <MobileAppDownloadBtn
                  idName="google-play-store"
                  storeBtn="google"
                  url={content.googlePlayStoreUrl}
                  specialBtn={content.heading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MobileOnlineBanking;

MobileOnlineBanking.defaultProps = {
  sectionClass: "bg-light",
  content: {
    imgColClass: "col-sm-12 col-md-4 col-lg-3",
    listColClass: "col-sm-12 col-md-8 col-lg-9",
    heading: "Mobile & Online Banking",
    headingClass: "",
    subHeading: "We make it easy to get your banking done on-the-go.",
    text: "",
    hasTwoImages: false,
    appleStoreUrl: "https://apps.apple.com/us/app/wafd-bank/id1054549841",
    googlePlayStoreUrl:
      "https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US",
    hasList: true,
    listData: {
      items: [
        {
          id: 1,
          text: "Pay bills online or with your smart phone."
        },
        {
          id: 2,
          text: "Check your balance, view transactions and transfer money."
        },
        {
          id: 3,
          text: "Deposit a check with your smart phone using mobile photo deposit."
        },
        {
          id: 4,
          text: "Pay a friend with person-to-person transfers."
        },
        {
          id: 5,
          text: "Deposit funds on your schedule or at our smart ATMs."
        }
      ]
    },
    primaryImgData: {
      imgVariable: "",
      altText: "WaFd Bank mobile app dashboard"
    },
    secondaryImgData: {
      imgVariable: "",
      altText: ""
    }
  }
};
